import React from 'react';
import part1ImgSrc from '../resource/part1.png'

export default class Back1 extends React.Component {
    constructor() {
        super();
        this.state = {
        };
    }
    render() {
        const styles = {
            zIndex: 2,
        };
        return (
            <>
                <img src={part1ImgSrc} style={{styles}} alt='only cloud' />
            </>
        );
    }
}