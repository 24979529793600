import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import part1ImgSrc from '../resource/part1.png'
import {requestFormURL} from './GlobalConstants'

export default function AutoAnimate() {
    const [timer, setTimer] = React.useState(1);
    const [formOpen, setFormOpen] = React.useState(false);
    const [visibilityInfo, setVisibilityInfo] = React.useState(false);
    const formDivRef = React.useRef();
    const [bodyFlashTimer, setBodyFlashTimer] = React.useState(0);
    let bodyFlashTimerId = null;
    const handleBodyOnClick = () => {
        if (!bodyFlashTimerId) {
            bodyFlashTimerId = setInterval(() => setBodyFlashTimer((pret) => { return pret + 1; }), 100);
            setTimeout(() => {
                if (bodyFlashTimerId) {
                    clearInterval(bodyFlashTimerId);
                    setVisibilityInfo(true);
                }
            }, 9 * 1000);
        }
    }

    React.useEffect(() => {
        window.document.body.style.background = 'linear-gradient(to right, #6dd5fa, #2980b9, #6dd5fa)';
        window.document.body.style.backgroundImage = 'url(./2014-08-sky-free-photo9.jpg)';
        window.document.body.style.backgroundSize = 'cover';
        window.document.body.style.backgroundRepeat = 'no-repeat';
        window.document.body.style.overflow = 'hidden';
        window.document.body.addEventListener('click', () => handleBodyOnClick());

        const timerId = setInterval(() => setTimer(timer + 1), 100);

        return () => {
            clearInterval(timerId);
        };
    });
    const styles1 = {
        zIndex: 2,
        marginLeft: '-' + timer + 'px',
        marginTop: '-' + timer + 'px',
    };
    const corpDivStype = {
        position: 'fixed',
        bottom: 0,
        width: '100%',
        textAlign: 'center',
        paddingBottom: '100px',
        fontSize: 24 + bodyFlashTimer + 'pt',
        color: 'rgba(0, 0, 0, 0.' + (bodyFlashTimer < 90 ? 90 - bodyFlashTimer : 0) + ')',
    };
    const corpInfoStyle = {
        zIndex: 3,
        position: 'fixed',
        display: visibilityInfo ? 'block' : 'none',
    };
    const requireFormStyle = {
        display: formOpen ? 'block' : 'none',
    };
    const openRequire= (e) => {
        setFormOpen(true);
        formDivRef.current.style.display = 'block';
    };
    return (
        <>
            <div style={corpInfoStyle}>
            <div>会社名：株式会社エーテルスミス　Ethersmith Co,. Ltd.</div>
            <div></div>
            <div>創業：2019年2月14日</div>
            <div></div>
            <div>資本金：１００万円（２０１９年２月現在）</div>
            <div></div>
            <div>所在地：〒162-0843</div>
            <div>東京都新宿区市谷田町２丁目２０－１－２０１</div>
            <div></div>
            <div>役員：代表取締役　小黒泰洋</div>
            <div></div>
            <div onClick={e => {return openRequire(e);}}>お問い合わせは<span style={{color: 'red'}}>こちら</span>から</div>
            <div style={requireFormStyle} ref={formDivRef}><iframe src="https://docs.google.com/forms/d/e/1FAIpQLSeFlHCj4l3rPtmOYda1mvtVB16H14nea9Ne2n_jLruAwaxuTA/viewform?embedded=true" width="640" height="574" frameborder="0" marginheight="0" marginwidth="0">読み込んでいます…</iframe></div>
            </div>
            <img src={part1ImgSrc} style={styles1} alt='only cloud' />
            <div style={corpDivStype}>株式会社エーテルスミス　Ethersmith Co,. Ltd.</div>
        </>
    );
}

const Home = () => {
    return <h2>Home</h2>;
}

const About = () => {
    return <h2>About</h2>;
}

const Contact = () => {
    return <h2><a href={requestFormURL} target='_new'>Contact</a></h2>;
}
