import React from 'react';
import Loader from '@trendmicro/react-loader';
import '@trendmicro/react-loader/dist/react-loader.css';
import Back1 from './Back1'

// https://www.npmjs.com/package/@trendmicro/react-loader
// npm install --save react @trendmicro/react-loader

export default class Loading extends React.Component {
    constructor() {
      super();
      this.state = {
      };
    }
    render() {
        return (
            <Loader size='lg' overlay={true} >
                <Back1 />
            </Loader>
        );
    }
}